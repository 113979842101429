angular.module("app")
	.controller("signinController", ["$log", "$state", "$location", "$q", "$window", "userService", "localStorageService", "authenticatedStatus", function($log, $state, $location, $q, $window, userService, localStorageService, authenticatedStatus){
		var ctrl = this;
		ctrl.signingIn = false;
		ctrl.message = undefined;
		
		ctrl.signin = function(username, password){
			ctrl.signingIn = true;
			userService.signIn(username, password)
			 	.then(function(authenticatedStatusResult){

                    if(authenticatedStatusResult.authenticated){

						authenticatedStatus.user = $q.defer();
                        authenticatedStatus.user.resolve(authenticatedStatusResult.user);
                        authenticatedStatus.directUser = authenticatedStatusResult.user;

						localStorageService.clearAll();

                        if (_.includes(authenticatedStatusResult.user.roles, "BUSINESS_ADMIN")) {
                            $state.go('departments');
                        } else {
                            $state.go("bookings");
                        }
                    } else {
                        ctrl.message = "Username and password do not match";
                    }
			 	})
			 	.finally(function(){
			 		ctrl.signingIn = false;
			 	});
		};
	}]);